<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Add Admin User</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="validForm"
          >
            <v-row>
              <v-col>
                <v-text-field
                    v-model="form.name"
                    :rules="[v => !!v || 'User name is required']"
                    label="User Name"
                    outlined
                    required
                    dense
                />
              </v-col>

              <v-col>
                <v-text-field
                    v-model="form.email"
                    :rules="[v => !!v || 'User email is required']"
                    label="User Email"
                    type="email"
                    outlined
                    required
                    dense
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                :cols="6"
              >
                <ApiAutoCompleteField
                  v-model="form.roles"
                  label="Roles"
                  path="roles"
                  multiple
                />
              </v-col>
            </v-row>

            <FormErrors ref="formErrors" />

          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="closeDialog">Close</v-btn>
          <v-btn
            :loading="loading"
            color="primary"
            text
            @click="createAdminUser"
          >
            Add User <v-icon small right>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar success ref="successBar">
      User created successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue creating the user.
    </SnackBar>
  </div>
</template>
<script type="text/javascript">
import HasDialog from '@/mixins/HasDialog'
import HasForm from '@/mixins/HasForm'
import ApiAutoCompleteField from '@/components/ApiAutoCompleteField'
import SnackBar from '@/components/SnackBar'
import FormErrors from '@/components/FormErrors'

export default {
  name: 'AddAdminUserDialog',

  mixins: [ HasDialog, HasForm ],

  components: {
    ApiAutoCompleteField,
    SnackBar,
    FormErrors
  },

  data () {
    return {
      loading: false
    }
  },

  methods: {
    createAdminUser () {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.$refs.formErrors.clear()
        this.$api.persist('post', {
          path: 'admin-users',
          object: this.form
        })
          .then(() => {
            this.$refs.successBar.open()
            this.closeDialog()
          })
          .catch(error => {
            this.$refs.formErrors.setErrors(error)
            this.$refs.errorBar.open()
          })
          .finally(() => { this.loading = false })
      }
    }
  }
}
</script>
