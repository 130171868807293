<template>
  <div>
    <v-btn
      class="mt-4"
      color="primary"
      @click="showAddDialog = true"
    >
      Add Admin User
      <v-icon
        small
        right
      >
        mdi-plus-circle-outline
      </v-icon>
    </v-btn>

    <DataTable
      :cols="cols"
      :request-params="{ with: ['roles'] }"
      default-order-by="created_at"
      path="admin-users"
    >
      <template v-slot:item.name="{ value }">
        <router-link :to="{ name: 'bookings.show', params: { bookingId: value } }">
          {{ value }}
        </router-link>
      </template>
      <template v-slot:item.roles="{ value: roles }">
        <div>
          <v-chip
            v-for="role in roles"
            :key="role.id"
            :to="{ name: 'roles.show', params: { roleId: role.id } }"
          >
            {{ role.name }}
          </v-chip>
        </div>
      </template>
    </DataTable>

    <AddAdminUserDialog
      :dialog.sync="showAddDialog"
    />
  </div>
</template>
<script type="text/javascript">
import DataTable from '@/components/DataTable'
import AddAdminUserDialog from '@/components/admin-users/AddAdminUserDialog'

export default {
  name: 'AdminUsers',

  components: {
    DataTable,
    AddAdminUserDialog
  },

  data () {
    return {
      showAddDialog: false,
      cols: [
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Roles', value: 'roles' },
      ]
    }
  }
}
</script>
